<template>
  <div id="app">
    <!-- 顶部导航 -->
    <div v-if="show" id="top-menu">
        <el-image
          style="width: 150px; height: 70px"
          :src="logo"
          :fit="'contain'">
        </el-image>
        <div class="right-icon">
            <el-avatar> user </el-avatar>
            <button class="loginout" @click="loginout">
                <span>Login out</span><i></i>
            </button>
        </div>
    </div>
    <!-- 左侧边栏 -->
    <div v-if="show" :class="['hamer',move]" @click="tocollapse" >
        <i  class="el-icon-s-fold"></i>
    </div>
    <div v-if="show" id="left-menu">
        <el-menu default-active="/"  class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse" router>
            <el-menu-item index="/">
              <i class="el-icon-s-platform"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="/mall">
              <i class="el-icon-goods"></i>
              <span slot="title">商业数据</span>
            </el-menu-item>
            <el-menu-item index="/tj">
              <i class="el-icon-office-building"></i>
              <span slot="title">出租统计</span>
            </el-menu-item>
            <el-menu-item index="/bill">
              <i class="el-icon-coin"></i>
              <span slot="title">账单统计</span>
            </el-menu-item>
            <el-menu-item index="/cbx">
              <i class="el-icon-coin"></i>
              <span slot="title">车备箱统计</span>
            </el-menu-item>
        </el-menu>
    </div>
    <div :id="content" :class="move">
        <router-view/>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {  
      show:false,
      logo:this.$logo,
      isCollapse: false,
      move:"",
      content:""
    }
  },
  methods: {
    // 自动登陆
    autologin(){
      if(localStorage.getItem("token")){
      //  this.logintype = true
       this.$router.replace({path:'/'})
      }else{
        // this.logintype = false
        this.$router.replace({path:'/login'})
      }
    },
    // 登出
    loginout(){
      this.$confirm('此操作会让你退出登陆,你目前尚未保存的内容也会消失,是否继续','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        this.$message({
          type:'success',
          message:"登出成功"
        })
        localStorage.removeItem('token')
        this.$router.replace({path:'/login'})
      })
    },
    // 侧边栏缩进展开
    handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
    handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
    tocollapse(){
      console.log('123123')
      if(this.move == ""){
        this.move = 'xs'
        this.isCollapse = true
        console.log(this.isCollapse)
      }else{
        this.move=""
        this.isCollapse = false
        console.log(this.isCollapse)
      }
    }

  },
  mounted() {
    // console.log(this.logintype)
    this.autologin()
  },
  watch: {
    '$route'(){
      console.log(this.$route.name)
      this.show = this.$route.name !== 'login'
      if(this.show == true){
        this.content = "content"
      } else{
        this.content = ""
      }
    }
  },
  created() {
    this.show = this.$route.name !== 'login'
    if(this.show == true){
        this.content = "content"
      } else{
        this.content = ""
      }
  },
}
</script>
<style scoped>
  .el-menu--collapse {
    height: 100%;
    box-shadow: 0 8px 12px #ebedf0;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 100%;
    min-height: 100%;
    box-shadow: 0 8px 12px #ebedf0;
  } 
  .el-menu-item.is-active{
    background-color: #ebfff0;
    border-radius: 999px;
    font-weight: 600;
    color: #4fc08d;
    font-size: 13px;  
  }
  .el-menu-item:hover{
    background: #ebfff0;
    color:#4fc08d ;
  }
  .loginout {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 10px 10px;
  transition: 0.5s;
  position: relative;
  font-size: 6px;
  background: #333;
  border:none;
  color: #fff;
  margin-left:10px ;
}

.loginout:hover {
  letter-spacing: 0.25em;
  background: #ff1867;
  color: #ff1867;
  box-shadow: 0 0 45px #ff1867;
}

.loginout::before {
  content: '';
  position: absolute;
  inset: 2px;
  background: #222222;
}

.loginout span {
  position: relative;
  z-index: 1;
}


</style>
