import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import './assets/css/mystle.css'
import axios from 'axios'


axios.defaults.timeout = 300000;
Vue.use(ElementUI);
Vue.config.productionTip = false
// Vue.prototype.$host = "http://localhost:80/"
// Vue.prototype.$logo = "http://localhost:80/img/kefan.png"
Vue.prototype.$host = "https://mall.kefan.com/api/"
Vue.prototype.$houtaiurl = "https://mall.kefan.com/"
Vue.prototype.$logo = "https://mall.kefan.com/img/kefan.png"
Vue.prototype.$logintype = false
Vue.prototype.$url = {
  // 登陆接口地址
  "login":"mallcategory/login.php",
  "banner":"mallcategory/banner.php",
  "mall":"mallcategory/mall.php",
  "classdata":"mallcategory/classdata.php",
  "malldata":"mallcategory/houtai/malldata.php",
  "maxnum":"mallcategory/houtai/maxnum.php",
  "malldetail":"mallcategory/houtai/malldetail.php",
  "correctdata":"mallcategory/houtai/correctdata.php",
  "form":"mallcategory/houtai/form.php",
  "upfile":"mallcategory/houtai/up.php",
  "mallalter":"mallcategory/houtai/mallalter.php",
  "alter":"mallcategory/houtai/alter.php",
  "delete":"mallcategory/houtai/delete.php",
  "ty":"mallcategory/houtai/ty.php",
  "empty":"mallcategory/houtai/empty.php",
  "excel":"mallcategory/houtai/excel.php",
  "bill":"mallcategory/houtai/bill.php",
  "init":"mallcategory/houtai/init.php",
  "billexcel":"mallcategory/houtai/billexcel.php",
  "cbxbill":"mallcategory/houtai/cbxbill.php",
  "cbxexcel":"mallcategory/houtai/cbxexcel.php",
}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
