import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/mall',
    name: 'mall',
    component: () => import('../views/mall.vue')
  },
  {
    path: '/tj',
    name: 'tj',
    component: () => import('../views/tj.vue')
  },
  {
    path: '/bill',
    name: 'bill',
    component: () => import('../views/bill.vue')
  },
  {
    path: '/cbx',
    name: 'cbx',
    component: () => import('../views/cbx.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
]

//解决router版本升级导致的跳转报错
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
  return routerPush.call(this,location).catch(err=>err)
}

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
